/*
  Miscellaneous utilities for assay annotation.
*/

import axios from 'axios';
import { Branch, OntologyTree } from './OntologyTree';
import { Schema } from './Schema';
import { OntologyTemplate, TemplateAssignment, TemplateGroup } from './templates';

export const ONTOLOGY_URL_BASE = '/ontologies/';
export const ONTOLOGY_URL_SFX = '?version=3';

let prefixMap: Record<string, string> = null; // must be loaded before use
let initAlready = false;

export function setOntologiesInitialized(value: boolean) {
  initAlready = value;
}

// call this before doing anything interesting with ontologies
export async function initializeOntologies(): Promise<void> {
  if (initAlready) return;

  const url = ONTOLOGY_URL_BASE + 'prefixes.json' + ONTOLOGY_URL_SFX;
  const response = await axios.get<Record<string, string>>(url);
  if (!response.data) throw new Error(`Unable to load ontology prefixes: ${url}`);
  prefixMap = response.data;

  await OntologyTree.props.init();
  await OntologyTree.values.init();

  initAlready = true;
}

export function areOntologiesInitialized(): boolean {
  return initAlready;
}

// used for unit tests which can't load files
export function initializeOntologiesDebug(debugPrefixMap: Record<string, string>): void {
  prefixMap = debugPrefixMap;
  initAlready = true;
}

// if the given URI has one of the common prefixes, replace it with the abbreviated version; if none, returns same as input
export function collapsePrefix(uri: string): string {
  if (!uri) return '';
  for (const pfx in prefixMap) {
    const stem = prefixMap[pfx];
    if (uri.startsWith(stem)) return pfx + uri.substring(stem.length);
  }
  return uri;
}
export function collapsePrefixes(uriList: string[]): string[] {
  if (uriList == null) return null;
  return uriList.map((uri) => collapsePrefix(uri));
}

// if the given proto-URI starts with one of the common prefixes, replace it with the actual URI root stem; if none, returns same as input
export function expandPrefix(uri: string): string {
  if (uri == null) return null;
  for (const pfx in prefixMap) {
    if (uri.startsWith(pfx)) return prefixMap[pfx] + uri.substring(pfx.length);
  }
  return uri;
}
export function expandPrefixes(uriList: string[]): string[] {
  if (uriList == null) return null;
  return uriList.map((uri) => expandPrefix(uri));
}

// convenient shortcuts comparing assignment group nesting: two separate concepts - 'same' is a more literal comparison which
// is appropriate for two assignments within the same template; 'compatible' means that they are not mutually exclusive, that
// being the appropriate way to compare assignments not necessarily from the same template
export function sameGroupNest(groupNest1: string[], groupNest2: string[]): boolean {
  const sz = groupNest1 ? groupNest1.length : 0, sz2 = groupNest2 ? groupNest2.length : 0;
  if (sz != sz2) return false;
  for (let n = 0; n < sz; n++) if (groupNest1[n] != groupNest2[n]) return false;
  return true;
}
export function compatibleGroupNest(groupNest1: string[], groupNest2: string[]): boolean {
  const sz = Math.min(groupNest1 ? groupNest1.length : 0, groupNest2 ? groupNest2.length : 0);
  for (let n = 0; n < sz; n++) if (groupNest1[n] != groupNest2[n]) return false;
  return true;
}
export function samePropGroupNest(propURI1: string, groupNest1: string[], propURI2: string, groupNest2: string[]): boolean {
  return propURI1 == propURI2 && sameGroupNest(groupNest1, groupNest2);
}
export function compatiblePropGroupNest(propURI1: string, groupNest1: string[], propURI2: string, groupNest2: string[]): boolean {
  return propURI1 == propURI2 && compatibleGroupNest(groupNest1, groupNest2);
}

// returns true if childNest is the same as parentNest, or is a descendent of it, i.e. childNest.length >= parentNest.length
export function descendentGroupNest(childNest: string[], parentNest: string[]): boolean {
  if (!childNest) childNest = [];
  if (!parentNest) parentNest = [];
  if (childNest.length < parentNest.length) return false;
  for (let i = 0; i < parentNest.length; i++) {
    const j = childNest.length - parentNest.length + i;
    if (parentNest[i] != childNest[j]) return false;
  }
  return true;
}

// formulates a token key from property & group hierarchy: this is used frequently for stashing assignments in dictionaries
export function keyPropGroup(propURI: string, groupNest: string[]): string {
  let key = propURI + '&&';
  if (groupNest != null) key += groupNest.join('::');
  return key;
}
export function keyPropGroupValue(propURI: string, groupNest: string[], value: string): string {
  return keyPropGroup(propURI, groupNest) + '&&' + value;
}

// unpacking the token keys above (value may be null if it's a prop/group key)
export function unpackKeyGroupValue(key: string): { propURI: string, groupNest: string[], value?: string } {
  const idx1 = key.indexOf('&&'), idx2 = key.indexOf('&&', idx1 + 2);
  if (idx1 < 0) return null;
  const propURI = key.substring(0, idx1);
  const groupStr = key.substring(idx1 + 2, idx2 < 0 ? key.length : idx2);
  const groupNest = groupStr ? groupStr.split('::') : [];
  const value = idx2 < 0 ? null : key.substring(idx2 + 2);
  return { propURI, groupNest, value };
}

// a handy utility that can be called within an async block, which gives the DOM a chance to update itself, by pausing for
// "zero" time; this can cause a performance hit within long loops, so use sparingly
export async function yieldDOM(): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(() => resolve()));
}

// duplicates a simple object, as long as it's made up dictionaries, arrays & primitives; would be better to use structuredClone,
// but this has not quite made its way to all browsers
export function deepClone<T>(data: T): T {
  if (data == null) return null;
  if (typeof data == 'function') return null;
  if (typeof data != 'object') return data;

  const result: any = Array.isArray(data) ? [] : {}; // eslint-disable-line @typescript-eslint/no-explicit-any
  for (const key in data) {
    const val = data[key];
    result[key] = typeof val === 'object' ? deepClone(val) : val;
  }
  return result as T;
}

// special circumstances for embedding ontology tree branches within each assignment object within a template, which is useful
// for external tools to obtain a snapshot of what the ontology composition looks like in real life; internally these are generated
// as needed from the raw materials
export async function embedSchemaTrees(template: OntologyTemplate): Promise<void> {
  interface TemplatePlusMap extends OntologyTemplate { prefixMap: Record<string, string>; }
  (template as TemplatePlusMap).prefixMap = prefixMap;

  const assnList: TemplateAssignment[] = [];
  const scanGroup = (group: TemplateGroup) => {
    assnList.push(...group.assignments);
    for (const sub of group.subGroups) scanGroup(sub);
  };
  scanGroup(template.root);

  interface BasicBranch {
    uri: string;
    label: string;
    children?: BasicBranch[];
  }
  const makeBasicBranch = (branch: Branch): BasicBranch => {
    const basic: BasicBranch = {
      uri: branch.uri,
      label: branch.label,
    };
    if (branch.children?.length > 0) {
      basic.children = branch.children.map((child) => makeBasicBranch(child));
    }
    return basic;
  };

  const schema = new Schema(template);
  for (const assn of assnList) {
    const branches = await schema.composeBranch(assn);
    if (!branches) continue; // nothing here
    const basicList = branches.map((branch) => makeBasicBranch(branch));

    interface AssnPlusTree extends TemplateAssignment { tree: BasicBranch }
    const assnTree = assn as AssnPlusTree;

    if (basicList.length == 1) {
      assnTree.tree = basicList[0];
    } else if (basicList.length > 1) {
      assnTree.tree = {
        uri: null,
        label: 'root',
        children: basicList,
      };
    }
  }
}
