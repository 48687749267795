import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { openModalComponent } from '@/components';

type Props = {
  message: string | JSX.Element | null;
  title?: string;

  additionalElements?: JSX.Element;
  noCancelOption?: boolean;

  terminology?: {
    YES?: string;
    NO?: string;
  };

  onConfirm?: () => void;
  onCancel?: () => void;
};

export const ConfirmationDialog = (props: Props) => {
  const { title, message, terminology, additionalElements, noCancelOption, onConfirm, onCancel } =
    props;
  let dialogContent = null;
  if (message) {
    dialogContent = (
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {additionalElements}
      </DialogContent>
    );
  }
  const displayTitle = title ?? 'Confirm';
  return (
    <>
      <Dialog
        open={true}
        onClose={onCancel}
        className='dialog__confirm'
      >
        {displayTitle ? <DialogTitle>{displayTitle}</DialogTitle> : null}

        <DialogContent>{dialogContent}</DialogContent>

        <DialogActions>
          {!noCancelOption && (
            <Button variant='text' onClick={onCancel} id='button_no'>
              {terminology?.NO ?? 'Cancel'}
            </Button>
          )}
          <Button variant='contained' onClick={onConfirm} id='button_yes'
            color='primary'>
            {terminology?.YES ?? 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default function askForConfirmation(props: Props): Promise<boolean> {
  return openModalComponent(ConfirmationDialog,
    {
      ...props,
    });
}
