import React from 'react';
import ReactDOM from 'react-dom';

export enum MessageType {
  'Error' = 'Error',
  'Success' = 'Success',
}
export type TGlobalMessage = {
  label: string;
  messageType: MessageType | null;
  rightButton?: { label: string; onClick: () => void };
};
const messageTypeClassNameMap: Record<MessageType, string> = {
  [MessageType.Error]: 'globalMessage-error',
  [MessageType.Success]: 'globalMessage-success',
};
type Props = { messages: TGlobalMessage[] };

class GlobalMessageInternal extends React.Component<Props> {
  render() {
    const { messages } = this.props;
    return (
      <>
        {messages.map(({ label, messageType, rightButton }, ii) => {
          const messageTypeClassName =
            'globalMessage' + messageType === null
              ? ''
              : ` ${messageTypeClassNameMap[messageType]}`;

          return (
            <div key={`error-${ii}`} className={messageTypeClassName}>
              <strong>{label}</strong>
              {rightButton && (
                <div
                  className='floatRight pseudo-link'
                  onClick={rightButton.onClick}
                >
                  {rightButton.label}
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  }
}

const getOrCreateGlobalMessageDiv = () => {
  // basically same logic here as in ruby/app/assets/javascript/application.js > insertGlobalMessage
  const container = document.getElementById('content-inner');
  // if doesn't already exist create, otherwise just return it
  if (!document.getElementById('globalMessages')) {
    const globalMessagesDiv = document.createElement('div');
    globalMessagesDiv.setAttribute('id', 'globalMessages');
    container.prepend(globalMessagesDiv);
  }
  return document.getElementById('globalMessages');
};

export class GlobalMessage extends React.Component<Props> {
  render() {
    const domNode = getOrCreateGlobalMessageDiv();
    return ReactDOM.createPortal(
      <GlobalMessageInternal {...this.props} />,
      domNode,
    );
  }
}
