import React from 'react';
import { ButtonDef } from '../DDForm/types/buttonDef';
import { layoutBuilder } from '../DDForm/layoutBuilder';
import { Icon } from '@mui/material';
import { Img } from '@/shared/components/sanitizedTags.js';
import HelpIcon from 'ASSETS/images/cdd30/icons/help.png';

import './cddElements.sass';
import { TypographyDef } from '../DDForm/types/typographyDef';

const deleteIconButton = (
  option: Partial<ButtonDef> & Pick<ButtonDef, 'key' | 'label'>,
) => {
  return layoutBuilder.button({
    buttonType: 'icon',
    className: 'delete-icon-button',
    width: 'default',
    label: '⊝',
    tooltip: 'Remove item',
    ...option,
  });
};

const groupIconButton = (
  option?: Partial<ButtonDef>,
) => {
  return layoutBuilder.button({
    key: 'icon',
    buttonType: 'icon',
    width: 'default',
    label: '👥',
    ...option,
  });
};

const helpIconButton = (
  option: Partial<TypographyDef> & Pick<ButtonDef, 'label'>,
) => {
  return layoutBuilder.typography({
    label: <><Icon className='help-icon'><Img src={HelpIcon} /></Icon></>,
    width: '1rem',
    ...option,
  });
};

export const CDDElements = {
  deleteIconButton,
  groupIconButton,
  helpIconButton,
};
