import { cancelBroadcastError } from '@/ErrorReporting/subscribe';
import { FieldDefinition } from '@/FieldDefinitions/types';
import {
  APIInventorySearchResults,
  InventorySearchViewAPIProps,
  ProcessedInventoryEntries,
} from '@/Inventory/components/types';
import { InventorySampleService } from '@/Samples/inventorySampleService';
import {
  EditInventoryEvent,
  EditSample,
  InventoryEvent,
  InventoryFieldValue,
  LocationValue,
  Sample,
} from '@/Samples/types';
import { ColumnDef } from '@/components';
import hasFeature from '@/shared/utils/features.js';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { keysAsNumbers } from '@/shared/utils/objectKeys';
import { term } from '@/shared/utils/stringUtils';
import { RootStore } from '@/stores/rootStore';
import axios, { Method } from 'axios';
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';

export const currentAmountForSample = (
  sample: Partial<Pick<Sample, 'current_amount'>>,
) => {
  return sample?.current_amount ?? 0;
};

export const locationForEvent = (
  event: Pick<
    InventoryEvent,
    'field_definitions' | 'inventory_event_fields'
  > | null,
) => {
  if (!event) {
    return null;
  }

  for (const field of event.field_definitions) {
    if (field.data_type_name === 'Location') {
      return event.inventory_event_fields[field.id] as LocationValue;
    }
  }
};

export const setEventCreditDebit = (
  event: Pick<InventoryEvent, 'field_definitions' | 'inventory_event_fields'>,
  amount: number,
) => {
  let creditFieldId, debitFieldId;

  event.field_definitions.forEach((field) => {
    if (field.name === 'Credit') {
      creditFieldId = field.id;
    }
    if (field.name === 'Debit') {
      debitFieldId = field.id;
    }
  });
  runInAction(() => {
    if (amount > 0) {
      event.inventory_event_fields[debitFieldId] = 0;
      event.inventory_event_fields[creditFieldId] = amount;
    } else {
      event.inventory_event_fields[creditFieldId] = 0;
      event.inventory_event_fields[debitFieldId] = -amount;
    }
  });
};

export type SampleTableData = Array<{
  group: Sample;
  rows: Array<InventoryEvent | Sample>;
}>;

export class SampleDataStore {
  disposers: Array<() => void> = [];
  inited = false;
  loadingCount = 0;
  inventory_sample_field_definitions: FieldDefinition[] = [];
  inventory_event_field_definitions: FieldDefinition[] = [];

  search_inventory_entries_url: InventorySearchViewAPIProps['links']['inventory_entries_url'];

  sampleLimitHit = false;
  samples: Array<Sample> = [];

  inventoryEntries: ProcessedInventoryEntries = [];

  currentlyEditingEvent: EditInventoryEvent = null;
  currentlyEditingSample: EditSample = null;
  currentlyRestoringSample = false;

  errorsSaveEvent: string[] = [];
  errorsSaveSample: string[] = [];

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  init() {
    reaction(() => {
      return this.shouldLoadInventoryFields;
    },
    async (shouldInit) => {
      if (shouldInit && !this.inited) {
        await this.loadInventoryFields();
        this.inited = true;
      }
    },
    { fireImmediately: true });
  }

  get shouldLoadInventoryFields() {
    const whitelistPaths = [
      '/inventory_search',
      '/molecules',
      '/inventory_field_definitions',
    ];
    const pathname = this.root.routerStore.url.pathname;
    return hasFeature('enableInventoryFields') && whitelistPaths.some((path) => pathname.includes(path));
  }

  get vaultId() {
    const { vaultId } = this.root.routerStore.extractFromPattern(
      '/vaults/:vaultId',
    ) ?? { vaultId: null };
    return vaultId as number;
  }

  get moleculeId() {
    const { routerStore } = this.root;
    const { moleculeId } = (routerStore.extractFromPattern(
      '/vaults/:vaultId/molecules/:moleculeId',
    ) as { vaultId?: number; moleculeId?: number }) ?? { moleculeId: null };
    return moleculeId;
  }

  get currentEventSample() {
    if (this.currentlyEditingEvent) {
      return this.samples.find(
        (sample) => sample.id === this.currentlyEditingEvent.inventory_sample_id,
      );
    }
    return null;
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoadingCount() {
    ++this.loadingCount;
  }

  decrementLoadingCount() {
    --this.loadingCount;
  }

  get loading() {
    return this.loadingCount > 0;
  }

  async loadInventoryFields() {
    const { routerStore } = this.root;
    const { vaultId } = (routerStore.extractFromPattern('/vaults/:vaultId') as {
      vaultId?: number;
    }) ?? { vaultId: null };

    if (vaultId === null) {
      return;
    }

    try {
      this.incrementLoadingCount();
      const result =
        await InventorySampleService.getInventoryFieldDefinitions();

      runInAction(() => {
        this.inventory_sample_field_definitions =
          result.data.inventory_sample_field_definitions;
        this.inventory_event_field_definitions =
          result.data.inventory_event_field_definitions;
        this.updateSamplesAndEvents(this.samples);
      });
    } finally {
      this.decrementLoadingCount();
    }
  }

  loadSamples(setLoading = false) {
    const promise = InventorySampleService.getSamples();
    if (promise) {
      setLoading && this.incrementLoadingCount();
      promise
        .then((result) => {
          this.setInventoryResults(
            result.data.inventory_samples,
            result.data.sample_limit_hit,
          );
        })
        .finally(() => {
          setLoading && this.decrementLoadingCount();
        });
    }
    return promise;
  }

  updateSamplesAndEvents(samples: Array<EditSample>) {
    const convertNameKeysToFieldIds = (
      fields: Record<string, InventoryFieldValue>,
      fieldDefinitions: FieldDefinition[],
    ) => {
      Object.keys(fields || {}).forEach((key) => {
        const fieldDefinition = fieldDefinitions.find(
          (field) => field.name === key,
        );
        if (fieldDefinition) {
          fields[fieldDefinition.id] = fields[key];
          delete fields[key];
        }
      });
    };
    if (
      this.inventory_sample_field_definitions &&
      this.inventory_event_field_definitions
    ) {
      samples.forEach((sample) => {
        sample.field_definitions = this.inventory_sample_field_definitions;
        sample.inventory_sample_fields_orig = {
          ...sample.inventory_sample_fields,
        };
        convertNameKeysToFieldIds(
          sample.inventory_sample_fields,
          this.inventory_sample_field_definitions,
        );
        sample.inventory_events?.forEach((event) => {
          event.field_definitions = this.inventory_event_field_definitions;

          event.inventory_event_fields_name_keyed = {
            ...event.inventory_event_fields,
          };
          convertNameKeysToFieldIds(
            event.inventory_event_fields,
            this.inventory_event_field_definitions,
          );
        });
      });
    }
    // if the event doesn't have a modified_by_user_full_name, set the modified_at to empty string
    // we only show the modified date if there's a user name
    for (const sample of samples) {
      for (const event of sample.inventory_events) {
        if (!event.modified_by_user_full_name) {
          event.modified_at = '';
        }
      }
    }
  }

  processInventorySearchEntries(
    samples: APIInventorySearchResults,
  ): ProcessedInventoryEntries {
    const convertNameKeysToFieldIds = (
      fields: Record<string, unknown>,
      fieldDefinitions: FieldDefinition[],
    ) => {
      const relevantDefs = fieldDefinitions.filter(
        (fieldDefinition) => fields[fieldDefinition.name] !== undefined,
      );
      return Object.fromEntries(
        relevantDefs.map((def) => [def.id, fields[def.name]]),
      );
    };

    const newSamples = samples.map((sample) => {
      return {
        ...sample,
        field_definitions: this.inventory_sample_field_definitions,
        inventory_sample_fields_orig: sample.inventory_sample_fields_hash,
        // inventory_sample_fields: convertNameKeysToFieldIds(
        //   sample.inventory_sample_fields,
        //   this.inventory_sample_field_definitions
        // ),
        inventory_events: sample.inventory_events_json.map((event) => {
          return {
            ...event,
            inventory_event_fields_name_keyed: event.inventory_event_fields_hash,
            inventory_event_fields: convertNameKeysToFieldIds(
              event,
              this.inventory_sample_field_definitions,
            ),
            // inventory_event_fields_name_keyed: event.inventory_event_fields,
            // if the event doesn't have a modified_by_user_full_name, set the modified_at to empty string
            // we only show the modified date if there's a user name
            modified_at: event.updated_by_user_full_name
              ? event.updated_date
              : '',
          };
        }),
      };
    });
    // TODO no any - better api-end typing
    return newSamples as any as ProcessedInventoryEntries;
  }

  get unitsForEvent() {
    const { currentlyEditingEvent } = this;
    const { inventory_sample_id } = currentlyEditingEvent ?? {};
    const sample = this.samples.find(
      (sample) => sample.id === inventory_sample_id,
    );
    return sample?.units || '';
  }

  handleCreateEvent(sample: EditSample) {
    const fieldDefinitionLocation = this.inventory_event_field_definitions.find(
      (field) => field.name === 'Location',
    );
    const location = sample?.location as LocationValue;

    const newEvent: EditInventoryEvent = {
      field_definitions: this.inventory_event_field_definitions,
      inventory_sample_id: sample.id,
      inventory_event_fields: {},
      inventory_event_fields_name_keyed: {},
    };
    // TBD: if we need to send an event with 0 credit/debit, uncomment this:
    // setEventCreditDebit(this.currentlyEditingEvent, 0);

    if (fieldDefinitionLocation && location) {
      newEvent.inventory_event_fields[fieldDefinitionLocation.id] =
        newEvent.inventory_event_fields_name_keyed.Location = location;
    }

    this.currentlyEditingEvent = newEvent;
    return newEvent;
  }

  handleEditEvent(event: InventoryEvent) {
    this.currentlyEditingEvent = deepClone(event);
  }

  handleError(error) {
    if (axios.isAxiosError(error)) {
      cancelBroadcastError(error);
      ModalUtils.showModal(
        error.response?.data?.[0] ?? 'An unknown error occurred',
        {
          title: 'Error',
          noCancelOption: true,
        },
      );
    }
  }

  addEventFieldsToFormData = (
    event: Partial<InventoryEvent>,
    formData: FormData,
    skipIds: Array<number> = [],
    prefix = 'inventory_event',
    sample = null,
  ) => {
    const data = { inventory_event: { ...toJS(event) } };

    const inventory_event_fields =
      data.inventory_event?.inventory_event_fields ?? {};

    const keys = keysAsNumbers(inventory_event_fields).filter(
      (key) => !skipIds.includes(key),
    );
    if (event.id && !sample?.is_single_use) {
      formData.append(`${prefix}[id]`, '' + event.id);
    }
    for (let i = 0; i < keys.length; i++) {
      const fieldId = keys[i];
      formData.append(
        `${prefix}[inventory_event_fields_attributes][${i}][field_definition_id]`,
        '' + fieldId,
      );
      let fieldValue = inventory_event_fields[fieldId] ?? '';
      if (typeof fieldValue === 'object') {
        if (fieldValue.uploaded_file_id) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fieldFormData = (fieldValue as any).formData;
          fieldValue = fieldValue.uploaded_file_id;
          if (fieldFormData) {
            for (const pair of fieldFormData.entries()) {
              if (pair[0] === 'qqfile') {
                formData.append(pair[0], pair[1]);
              }
            }
          }
        } else if (
          fieldValue.id &&
          fieldValue.value &&
          fieldValue.position !== undefined
        ) {
          fieldValue = `${fieldValue.id},${fieldValue.position}`; // location
        }
      }
      formData.append(
        `${prefix}[inventory_event_fields_attributes][${i}][value]`,
        '' + fieldValue,
      );
    }
    if (!keys.length) {
      formData.append(`${prefix}[inventory_event_fields_attributes][0]`, '');
    }
  };

  handleSubmitEvent() {
    const { routerStore } = this.root;
    const event = this.currentlyEditingEvent;
    const { vaultId, moleculeId } = routerStore.extractFromPattern(
      '/vaults/:vaultId/molecules/:moleculeId',
    ) as { vaultId?: number; moleculeId?: number };

    const url = `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples/${event.inventory_sample_id}`;
    const method: Method = 'put';
    const formData = new FormData();
    const skipIds = [];

    // if we're saving with the same location as the sample, don't save the location
    const fieldDefinitionLocation = this.inventory_event_field_definitions.find(
      (field) => field.name === 'Location',
    );

    if (fieldDefinitionLocation) {
      const location = event.inventory_event_fields[
        fieldDefinitionLocation.id
      ] as LocationValue;
      const sample = this.samples.find(
        (sample) => sample.id === event.inventory_sample_id,
      );
      const sampleLocation = sample?.location;

      if (
        location?.id === sampleLocation?.id &&
        location?.position === sampleLocation?.position
      ) {
        skipIds.push(fieldDefinitionLocation.id);
      }
    }
    this.addEventFieldsToFormData(
      event,
      formData,
      skipIds,
      'inventory_sample[inventory_events_attributes][0]',
    );

    // if we're submitting an event to resolve a depleted sample restoration, set depleted to false
    if (this.currentlyRestoringSample) {
      formData.append('inventory_sample[depleted]', 'false');
    }

    axios({
      url,
      method,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(() => {
        this.handleCancelEditEvent();
        this.loadSamples();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  handleCancelEditEvent() {
    this.currentlyEditingEvent = null;
    this.currentlyRestoringSample = false;
  }

  // samples
  handleCreateSample() {
    // initialize a new empty sample for editing, which will cause the dialog to show
    this.currentlyEditingSample = {
      field_definitions: this.inventory_sample_field_definitions,
      inventory_events: [
        {
          field_definitions: this.inventory_event_field_definitions,
          inventory_event_fields: {},
        } as InventoryEvent,
      ],
    };
  }

  handleEditSample(sample?: EditSample) {
    const clonedSample = deepClone<EditSample>(
      sample ?? { inventory_events: [] },
    );
    // set the first event if there is none for a single use sample
    if (
      sample.is_single_use &&
      !sample.inventory_events[0].inventory_event_fields
    ) {
      // eslint-disable-next-line dot-notation
      clonedSample['inventory_events'] = [
        {
          field_definitions: this.inventory_event_field_definitions,
          inventory_event_fields: {},
        } as InventoryEvent,
      ];
    }
    this.currentlyEditingSample = clonedSample;
  }

  handleSubmitSample = async (
    sample: EditSample = null,
    forceIncludeEvents = false,
  ) => {
    sample = sample ?? this.currentlyEditingSample;
    const { routerStore } = this.root;

    const { vaultId, moleculeId } = routerStore.extractFromPattern(
      '/vaults/:vaultId/molecules/:moleculeId',
    ) as { vaultId?: number; moleculeId?: number };

    let url = `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples`;
    if (sample.id) {
      url += `/${sample.id}`;
    }

    const formData = new FormData();
    const { inventory_sample_fields = {} } = toJS(sample);

    ['id', 'name', 'units', 'batch_id', 'depleted'].forEach((key) => {
      if (sample[key] !== undefined) {
        formData.append(`inventory_sample[${key}]`, sample[key]);
      }
    });

    keysAsNumbers(inventory_sample_fields).forEach((fieldId, i) => {
      formData.append(
        `inventory_sample[inventory_sample_fields_attributes][${i}][field_definition_id]`,
        '' + fieldId,
      );
      let fieldValue = inventory_sample_fields[fieldId] ?? '';
      if (typeof fieldValue === 'object' && fieldValue.uploaded_file_id) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fieldFormData = (fieldValue as any).formData;
        fieldValue = fieldValue.uploaded_file_id;
        if (fieldFormData) {
          for (const pair of fieldFormData.entries()) {
            if (pair[0] === 'qqfile') {
              formData.append(pair[0], pair[1]);
            }
          }
        }
      }

      formData.append(
        `inventory_sample[inventory_sample_fields_attributes][${i}][value]`,
        '' + fieldValue,
      );
    });

    if (
      (sample.inventory_events?.length && !sample.id) ||
      sample.is_single_use ||
      forceIncludeEvents
    ) {
      // when creating a new sample, add the inventory event fields as well
      const skipIds = [];
      const event = sample.inventory_events[0];

      if (sample.is_single_use && sample.current_amount > 0) {
        setEventCreditDebit(sample.inventory_events[0], 0);
      }

      this.addEventFieldsToFormData(
        event,
        formData,
        skipIds,
        'inventory_sample[inventory_events_attributes][0]',
        sample,
      );
    }
    const method = sample.id ? 'put' : 'post';

    // if we're updating a sample to resolve a depleted sample restoration, set depleted to false
    if (this.currentlyRestoringSample) {
      formData.append('inventory_sample[depleted]', 'false');
    }
    try {
      await axios({
        url,
        method,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.handleCancelEditSample();

      await this.loadSamples();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          cancelBroadcastError(error);
          this.handleEditLocationForRestoredSample(sample);
          return;
        }
      }
      this.handleError(error);
    }
  };

  handleEditLocationForRestoredSample(sample: EditSample) {
    sample.depleted = true;
    this.currentlyRestoringSample = true;
    if (sample && sample.is_single_use) {
      this.handleEditSample(sample as Sample);
    } else {
      this.handleCreateEvent(sample);
    }
  }

  async handleDeleteSample() {
    const { currentlyEditingSample } = this;
    const { routerStore } = this.root;
    const { vaultId, moleculeId } = routerStore.extractFromPattern(
      '/vaults/:vaultId/molecules/:moleculeId',
    ) as { vaultId?: number; moleculeId?: number };

    if (
      await ModalUtils.confirmDelete(
        `${term('sample')} "${currentlyEditingSample.name}"`,
      )
    ) {
      const url = `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples/${currentlyEditingSample.id}`;
      await axios.delete(url);

      this.handleCancelEditSample();
      this.loadSamples();
    }
  }

  async handleDeleteEvent() {
    const event = this.currentlyEditingEvent;
    const { routerStore } = this.root;
    const { vaultId, moleculeId } = routerStore.extractFromPattern(
      '/vaults/:vaultId/molecules/:moleculeId',
    ) as { vaultId?: number; moleculeId?: number };

    if (await ModalUtils.confirmDelete(`this ${term('event', true)}`)) {
      const url = `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples/${event.inventory_sample_id}`;
      const method: Method = 'put';
      const formData = new FormData();
      formData.append(
        'inventory_sample[inventory_events_attributes][0][id]',
        `${event.id}`,
      );
      formData.append(
        'inventory_sample[inventory_events_attributes][0][_destroy]',
        '1',
      );
      try {
        await axios({
          url,
          method,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        runInAction(() => {
          this.loadSamples();
          this.currentlyEditingEvent = null;
        });
      } catch (error) {
        this.handleError(error);
      }
    }
  }

  handleCancelEditSample() {
    this.currentlyRestoringSample = false;
    this.currentlyEditingSample = null;
  }

  handleToggleDeplete(sample: Sample) {
    sample.depleted = !sample.depleted;
    this.handleSubmitSample(sample);
  }

  setInventoryResults(samples: Array<Sample>, sampleLimitHit: boolean) {
    this.updateSamplesAndEvents(samples);
    this.samples = samples;
    this.sampleLimitHit = sampleLimitHit;
  }

  get displayedColumns() {
    const result = [
      {
        id: 'created_at',
        label: 'Created',
        hideIfEmpty: true,
      },
      {
        id: 'modified_at',
        label: 'Last Modified',
        hideIfEmpty: true,
      },
      {
        id: 'debit_credit',
        label: 'Debit/Credit',
      },
      {
        id: 'location',
        label: 'Location',
      },
      ...this.inventory_event_field_definitions
        .filter(
          (field) => !['Credit', 'Debit', 'Location'].includes(field.name),
        )
        .map((field) => ({
          id: `inventory_event_fields.${field.id}`,
          label: field.name,
          hideIfEmpty: true,
        })),
    ];

    return result;
  }

  get singleUseColumns() {
    const result: Array<ColumnDef> = [
      {
        id: 'created_at',
        label: 'Created',
        hideIfEmpty: true,
      },
      {
        id: 'modified_at',
        label: 'Last Modified',
        hideIfEmpty: true,
      },
      {
        id: 'current_amount',
        label: 'Amount',
      },
      ...this.inventory_sample_field_definitions.map((field) => ({
        id: `inventory_sample_fields.${field.id}`,
        label: field.name,
        hideIfEmpty: true,
      })),
      {
        id: 'location',
        label: 'Location',
      },
      ...this.inventory_event_field_definitions
        .filter(
          (field) => !['Credit', 'Debit', 'Location'].includes(field.name),
        )
        .map((field) => ({
          id: `inventory_events[0].inventory_event_fields.${field.id}`,
          label: field.name,
          hideIfEmpty: true,
        })),
      {
        id: 'deplete',
        width: 20,
        label: '',
        hideIfEmpty: false,
      },
    ];

    return result;
  }

  get sampleTableData(): SampleTableData {
    const { samples } = this;

    // first collect all non single use samples
    const result: Array<{
      group: Sample;
      rows: Array<InventoryEvent | Sample>;
    }> = samples
      .filter((sample) => !sample.is_single_use)
      .map((sample) => ({
        group: sample,
        rows: (
          sample.inventory_events?.slice() ?? ([] as InventoryEvent[])
        ).sort((a, b) => {
          return b.created_at.localeCompare(a.created_at);
        }),
      }));

    // now group single use samples by batch
    const batchToSamples: Record<number, Array<Sample>> = {};
    samples
      .filter((sample) => sample.is_single_use)
      .forEach((sample) => {
        batchToSamples[sample.batch_id] = [
          ...(batchToSamples[sample.batch_id] ?? []),
          sample,
        ];
      });

    // and add grouped single use samples to result
    Object.values(batchToSamples).forEach((samples) => {
      const group = { ...samples[0] };
      const { name, sample_identifier } = samples[0];

      // remove sample identifier from group name
      if (sample_identifier && name.endsWith(`-${sample_identifier}`)) {
        group.name = group.name.substring(
          0,
          group.name.length - sample_identifier.length - 1,
        );
      }
      result.push({
        group: {
          ...group,
          depleted: samples.every((sample) => sample.depleted),
        },
        rows: samples
          .sort((a, b) => {
            return b.created_at.localeCompare(a.created_at);
          })
          .sort((a, b) => {
            return (a.depleted ? 1 : 0) - (b.depleted ? 1 : 0);
          }),
      });
    });

    // sort by single use (first), then by batch name, then by depleted
    result
      .sort(
        (a, b) =>
          (b.group.is_single_use ? 1 : 0) - (a.group.is_single_use ? 1 : 0),
      )
      .sort((a, b) => {
        return a.group.batch_name.localeCompare(b.group.batch_name);
      })
      .sort((a, b) => {
        return (a.group.depleted ? 1 : 0) - (b.group.depleted ? 1 : 0);
      });

    return result;
  }
}
