import React from 'react'
import { OrderedMap } from 'immutable'
import SubmitOrCancel from './SubmitOrCancel'

import { A } from '@/shared/components/sanitizedTags.js'

class KeyHandlerStack {
  constructor() {
    let handlers = OrderedMap()

    const callLastHandler = event => {
      const last = handlers.last()
      last(event)
    }

    this.on = (adder, handler) => {
      const wasEmpty = handlers.isEmpty()

      handlers = handlers.set(adder, handler)

      if (wasEmpty) {
        $(document).on('keydown', callLastHandler)
      }
    }

    this.off = adder => {
      handlers = handlers.delete(adder)

      if (handlers.isEmpty()) {
        $(document).off('keydown', callLastHandler)
      }
    }
  }
}
const keyHandlerStack = new KeyHandlerStack()

export default class CancelOrSubmit extends SubmitOrCancel {
  orCancel() {
    const { onCancel, noCancelOption } = this.props

    if (!noCancelOption) {
      return (
        <span className="orcancel">
          <A
            className="cancel"
            onClick={onCancel}
            href="#"
            style={{ color: 'black', margin: '0px 20px 0px 0px' }}
          >
            CANCEL
          </A>
        </span>
      )
    }
  }

  render() {
    const { loading, small, onSubmit, green, submitLabel, linkUrl, disableMessage } = this.props
    const disabled = this.props.disabled || loading

    let className = 'buttony'
    if (small) { className += ' buttony-small' }
    if (green) { className += ' buttony-green' }
    if (disabled) { className += ' disabled' }

    const title = (disabled) ? disableMessage : ''

    return (
      <div className="buttons-react">
        <div className="buttons-right">
          {this.orCancel()}
          <span className="buttony__container">
            <A
              className={className}
              title={title}
              onClick={onSubmit}
              href={linkUrl || '#'}
              data-method={linkUrl ? 'post' : null}
            >
              {submitLabel || this.props.children}
            </A>
            {this.renderSpinner()}
          </span>
        </div>
      </div>
    )
  }
}
