import { cancelBroadcastError } from '@/ErrorReporting/subscribe';
import { TInventoryFilter } from '@/Inventory/components/SearchBar/Filter/FilterFields/FilterValueSelect/FilterValueSelect.types';
import { buildFieldDefinitions } from '@/Inventory/components/buildFieldDefinitions';
import {
  APIInventorySearchResults,
  CustomOrDefaultResultColumnId,
  FieldType,
  FlattenedInventoryEvent,
  InventoryResultColumn,
  InventorySearchViewAPIProps,
  InventorySearchViewJsonAPIProps,
  ProcessedInventoryEntries,
  TSearchHighlight,
} from '@/Inventory/components/types';
import { QueryFilter } from '@/Inventory/models/QueryFilter';
import { ColumnForDisplay } from '@/Protocols/types';
import { InventorySampleService } from '@/Samples/inventorySampleService';
import { EditInventoryEvent, EditSample, TSearchQuery } from '@/Samples/types';
import { ColumnSortDef } from '@/components';
import { Column } from '@/components/ColumnsEditor/ColumnsEditor';
import {
  MessageType,
  TGlobalMessage,
} from '@/components/GlobalMessage/GlobalMessage';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { compressForParam } from '@/shared/utils/moleculeImageHelper';
import { term } from '@/shared/utils/stringUtils';
import { RootStore } from '@/stores/rootStore';
import { VaultPreferences } from '@/stores/vaultPreferencesStore';
import { KeysToCamelCase, shallowCamelCaseKeys } from '@/typeCasings';
import { CDD } from '@/typedJS';
import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import {
  EncodedFieldString,
  encodeFieldTypeInId,
  unencodeFieldTypeFromId,
} from '../components/Table/encodeUtils';
import { InventoryStoreHelper } from './InventoryStoreHelper';

export type InventoryFieldUniqueKey = {
  //  field_type is to help us distinguish between inventory sample fields & inventory event fields
  field_type: FieldType;
  id: CustomOrDefaultResultColumnId;
};
// 'totalNumChars' includes length of ellipses
const ellipsizeString = (fullLenString: string, totalNumChars: number) => {
  if (fullLenString.length <= totalNumChars) {
    return fullLenString;
  }
  const ellipses = '...';
  return `${fullLenString.slice(
    0,
    totalNumChars - ellipses.length,
  )}${ellipses}`;
};
export class InventoryStore {
  disposers: Array<() => void> = [];
  inited = false;
  loadingCount = 0;
  searchPage = 0;

  inventory_field_definitions: InventorySearchViewAPIProps['custom_field_definitions'];

  search_inventory_entries_url: InventorySearchViewAPIProps['links']['inventory_entries_url'];
  inventory_entries_export_url: InventorySearchViewAPIProps['links']['inventory_entries_export_url'];
  inventory_entries_export_progress_url: InventorySearchViewAPIProps['links']['inventory_entries_export_progress_url'];

  sampleLimitHit = false;

  totalCount: InventorySearchViewAPIProps['total_count'] = -1;
  inventoryEntries: ProcessedInventoryEntries = [];
  searchHighlights: TSearchHighlight = {};

  queryFilters: (TSearchQuery['query_filters'][number] | null)[] = [];
  queryText: TSearchQuery['text'] = null;
  queryMRV: TSearchQuery['mrv'] = null;

  currentlyEditingInventory: Partial<ProcessedInventoryEntries[number]> = null;
  currentlyEditingChildIndex: number | null = null;

  errors: ({ id: number } & TGlobalMessage)[] = [];

  get hasErrors() {
    return this.errors.length > 0;
  }

  private async sendSearchQuery(): Promise<
    KeysToCamelCase<InventorySearchViewJsonAPIProps>
    > {
    try {
      const retrievedData = await axios.post<InventorySearchViewJsonAPIProps>(
        this.search_inventory_entries_url,
        {
          ...this.serializedSearchQuery,
          page: this.searchPage,
        },
      );
      return shallowCamelCaseKeys(retrievedData.data);
    } catch (error) {
      this.handleError(error);
    }
  }

  init() {
    if (this.inited) {
      return;
    }
    this.inited = true;
  }

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  get inventoryColumnPrefs() {
    return { ...this.root.vaultPreferencesStore.inventoryColumnPrefs };
  }

  updatePreferences(newPrefs: Partial<VaultPreferences>) {
    this.root.vaultPreferencesStore.updatePreferences(newPrefs);
  }

  setPreferences(newPrefs: VaultPreferences['inventoryColumnPrefs']) {
    this.root.vaultPreferencesStore.setPreference('inventoryColumnPrefs', {
      ...this.inventoryColumnPrefs,
      ...newPrefs,
    });
  }

  get searchQuery(): TSearchQuery {
    return {
      text: this.queryText,
      query_filters: this.queryFilters,
      mrv: this.queryMRV,
    };
  }

  get serializedSearchQuery() {
    return {
      text: this.queryText === null ? '' : this.queryText,
      query_filters:
        this.queryFilters.length === 0
          ? undefined
          : this.queryFilters.map((val) => val.serialize()),
      mrv: this.queryMRV === null ? undefined : compressForParam(this.queryMRV),
    };
  }

  get vaultId() {
    const { vaultId } = this.root.routerStore.extractFromPattern(
      '/vaults/:vaultId',
    ) ?? { vaultId: null };
    return vaultId as number;
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  async resetInventoryPreferences() {
    this.root.vaultPreferencesStore.setPreference(
      'inventoryColumnPrefs',
      undefined,
    );
    this.resetSearchQuery();
    await this.submitQuery();
  }

  incrementLoadingCount() {
    ++this.loadingCount;
  }

  decrementLoadingCount() {
    --this.loadingCount;
  }

  get loading() {
    return this.loadingCount > 0;
  }

  setSortBy(value: ColumnSortDef<CustomOrDefaultResultColumnId>) {
    this.updatePreferences({
      inventoryColumnPrefs: {
        sortedColumn: value,
      },
    });
  }

  get visibleColumnIds() {
    return this.inventoryColumnPrefs.visibleColumnIds;
  }

  get availableColumns(): ColumnForDisplay<
    CustomOrDefaultResultColumnId,
    { field_type: FieldType }
  >[] {
    const idsToColumns: Record<
      FieldType,
      Column<CustomOrDefaultResultColumnId>[]
    > = {
      Event: [],
      Sample: [],
      Molecule: [],
    };

    Object.entries(this.inventoryFieldDefinitions.byFieldThenVault).forEach(
      ([fieldType, vaultObj]) => {
        const allObjectsForThisVault = Object.values(vaultObj).flat();
        const sortedVaultValues = allObjectsForThisVault.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        idsToColumns[fieldType] = sortedVaultValues.map((vaultEntry) => {
          const translatedLabel = term(vaultEntry.name.toLowerCase(), true);
          return {
            ...vaultEntry,
            label: translatedLabel,
            name: translatedLabel,
          };
        });
      },
    );
    const values = Object.entries(idsToColumns).map(([fieldType, vaultObj]) => {
      return {
        id: fieldType as CustomOrDefaultResultColumnId,
        label: fieldType as FieldType,
        name: fieldType as FieldType,
        field_type: fieldType as FieldType,
        children: vaultObj.map((val) => ({
          ...val,
          field_type: fieldType as FieldType,
        })),
      };
    });
    return values;
  }

  get sortedColumn() {
    return this.inventoryColumnPrefs
      .sortedColumn as ColumnSortDef<CustomOrDefaultResultColumnId>;
  }

  get displayedColumns(): InventoryResultColumn[] {
    const result = this.visibleColumnIds
      .map((val) => {
        const { id, field_type } = unencodeFieldTypeFromId(
          val as EncodedFieldString,
        );
        const foundDef =
          this.inventoryFieldDefinitions.byFieldThenSelectValue[field_type][id];
        return { id, label: foundDef.name, name: foundDef.name };
      })
      .filter((def) => !!def)
      .map((def) => ({ ...def, label: def.name }));
    const sortedColumn = this.sortedColumn;
    return result.map((column) => {
      if (`${column?.id}` == `${sortedColumn.id}`) {
        return {
          ...column,
          id: column.id as CustomOrDefaultResultColumnId,
          direction: sortedColumn.direction,
        };
      }
      return { ...column, id: column.id as CustomOrDefaultResultColumnId };
    });
  }

  get visibleColumns(): InventoryResultColumn[] {
    return this.displayedColumns;
  }

  setVisibleColumnDetails(details: Array<InventoryFieldUniqueKey>) {
    this.root.vaultPreferencesStore.setPreference('inventoryColumnPrefs', {
      visibleColumnIds: details.map((detail) => encodeFieldTypeInId(detail)),
    });
  }

  async handleToggleDeplete(entry: FlattenedInventoryEvent) {
    if (!(await ModalUtils.confirmDepleteSample(entry.name))) {
      return;
    }
    const sample = InventoryStoreHelper.convertToInventorySample(
      entry,
      this.inventory_event_field_definitions,
    );
    sample.depleted = !sample.depleted;
    InventorySampleService.handleSubmitEditSample({
      sample,
      moleculeId: entry.batch.molecule_id,
      on409Error: async () => {
        this.handleError('409 Error');
      },
      onGenericError: async (error) => {
        this.handleError(`Request Error: ${error}`);
      },
      onSubmitSuccess: () => {
        this.submitQuery();
      },
    });
  }

  get inventory_event_field_definitions() {
    return this.inventory_field_definitions.Event;
  }

  get inventory_sample_field_definitions() {
    return this.inventory_field_definitions.Sample;
  }

  get currentlyEditingInventoryAsSample(): EditSample | null {
    const { currentlyEditingInventory } = this;
    if (!currentlyEditingInventory) {
      return null;
    }

    const result: EditSample = InventoryStoreHelper.convertToInventorySample(
      currentlyEditingInventory,
      this.inventory_event_field_definitions,
    );

    this.root.sampleDataStore.updateSamplesAndEvents([result]);
    return result;
  }

  get currentlyEditingInventoryAsEvent(): EditInventoryEvent | null {
    const { currentlyEditingInventory, currentlyEditingChildIndex } = this;
    if (!currentlyEditingInventory || currentlyEditingChildIndex === null) {
      return null;
    }

    const result = InventoryStoreHelper.convertEntryToInventoryEvent(
      // Child Index + 1 because inventory events includes the root index
      currentlyEditingInventory.inventory_events[
        currentlyEditingChildIndex + 1
      ],
      currentlyEditingInventory.id,
      this.inventory_event_field_definitions,
    );
    return result;
  }

  get currentlyEditingInventoryBatches() {
    const batch = this.currentlyEditingInventory?.batch;
    if (batch) {
      return [{ ...batch, id: batch.batch_id, name: batch.batch_name }];
    }
    return [];
  }

  setupInventorySearchPage({
    inventory_field_definitions,
    links: {
      inventory_entries_url,
      inventory_entries_export_progress_url,
      inventory_entries_export_url,
    },
    initial_entries,
    saved_filters,
    result_columns_available,
    total_count,
  }: Pick<
    InventorySearchViewAPIProps,
    | 'initial_entries'
    | 'result_columns_available'
    | 'links'
    | 'saved_filters'
    | 'total_count'
  > & {
    inventory_field_definitions: InventorySearchViewAPIProps['default_field_definitions'];
  }) {
    this.search_inventory_entries_url = inventory_entries_url;
    this.inventory_entries_export_url = inventory_entries_export_url;
    this.inventory_entries_export_progress_url =
      inventory_entries_export_progress_url;

    this.inventory_field_definitions = inventory_field_definitions;

    this.totalCount = total_count;

    this.processSearchedInventoryEntries(initial_entries, false);
    this.queryFilters = saved_filters.map((val) => {
      return new QueryFilter(val);
    });
  }

  processInventorySearchEntries(
    samples: APIInventorySearchResults,
  ): ProcessedInventoryEntries {
    const processedEntries = samples.map(
      (sample): ProcessedInventoryEntries[number] => {
        return {
          ...sample,
          ...sample.batch,
          sample_created_date: sample.created_date,
          sample_created_by_user_full_name: sample.created_by_user_full_name,

          sample_updated_by_user_full_name: sample.updated_by_user_full_name,
          sample_updated_date: sample.updated_date,

          inventory_sample_fields_orig: sample.inventory_sample_fields_hash,
          inventory_sample_fields:
            InventoryStoreHelper.convertNameKeysToFieldIds(
              sample.inventory_sample_fields_hash,
              this.inventory_sample_field_definitions,
            ),
          inventory_event_fields: sample.inventory_events_json[0]
            ? InventoryStoreHelper.convertNameKeysToFieldIds(
              sample.inventory_events_json[0].inventory_event_fields_hash,
              this.inventory_event_field_definitions,
            )
            : undefined,
          inventory_events: sample.inventory_events_json.map(
            (
              event,
            ): ProcessedInventoryEntries[number]['inventory_events'][number] => {
              return {
                event_id: event.event_id,
                inventory_event_fields_hash: event.inventory_event_fields_hash,
                created_by_user_id: event.created_by_user_id,
                event_created_by_user_full_name:
                  event.created_by_user_full_name,
                event_created_date: event.created_date,
                event_modified_date: event.updated_date,
                event_modified_by_user_full_name:
                  event.updated_by_user_full_name,
                inventory_event_fields_name_keyed:
                  event.inventory_event_fields_hash,
                inventory_event_fields:
                  InventoryStoreHelper.convertNameKeysToFieldIds(
                    event.inventory_event_fields_hash,
                    this.inventory_event_field_definitions,
                  ),
              };
            },
          ),
        };
      },
    );
    return processedEntries;
  }

  removeError(errorIndex: number) {
    this.errors = this.errors.filter((val) => val.id !== errorIndex);
  }

  handleError(error) {
    if (axios.isAxiosError<string, string>(error)) {
      cancelBroadcastError(error);
      const errorText =
        ellipsizeString(error.response.data, 50) ?? 'An unknown error occurred';
      const id = this.errors.length;
      this.errors.push({
        id,
        label: errorText,
        messageType: MessageType.Error,
        rightButton: {
          label: 'Dismiss',
          onClick: () => {
            this.removeError(id);
          },
        },
      });
    }
  }

  get inventoryFieldDefinitions() {
    return buildFieldDefinitions(this.inventory_field_definitions);
  }

  addQueryFilter = () => {
    this.queryFilters = [...this.queryFilters, null];
  };

  updateQueryText = ({ text }: Pick<TSearchQuery, 'text'>) => {
    this.queryText = text || null;
  };

  updateQueryMRV = ({ mrv }: Pick<TSearchQuery, 'mrv'>) => {
    this.queryMRV = mrv || null;
  };

  updateQueryFilter = ({
    filter,
    index,
  }: {
    filter: TInventoryFilter;
    index: number;
  }) => {
    // spread here to update pointer
    const mutableFilters = [...this.queryFilters];
    mutableFilters[index] = new QueryFilter(filter);
    this.queryFilters = mutableFilters;
  };

  resetSearchQuery = () => {
    this.resetQueryFilters();
    this.updateQueryText({ text: undefined });
    this.updateQueryMRV({ mrv: undefined });
  };

  removeQueryFilter = (index) => {
    this.queryFilters = this.queryFilters.filter((_, ii) => ii !== index);
  };

  submitExport = async () => {
    const emptiedQuery = Object.fromEntries(
      Object.entries(this.searchQuery).filter(([, val]) => {
        return val !== '' && val !== null;
      }),
    );
    CDD.Export.submit(
      this.inventory_entries_export_url,
      this.inventory_entries_export_progress_url,
      emptiedQuery,
    );
  };

  resetQueryFilters = () => {
    this.queryFilters = [];
  };

  getNextPage = async (page: number) => {
    this.searchPage = page;
    this.submitQuery({
      appending: true,
      onError: () =>
        // if queries error out and we're trying to get the next page, without this
        // the modal would freeze the position on the page and keep sending queries & opening new modals forever
        window.scrollTo(0, 0),
    });
  };

  submitNewQuery = async () => {
    this.searchPage = 0;
    this.submitQuery();
  };

  private submitQuery = async (
    { appending, onError }: { appending?: boolean; onError?: () => void } = {
      appending: false,
    },
  ) => {
    this.incrementLoadingCount();
    try {
      const { highlights, inventoryEntries, totalCount } =
        await this.sendSearchQuery();
      this.processSearchedInventoryEntries(inventoryEntries, false, appending);
      this.searchHighlights = highlights;
      this.totalCount = totalCount;
    } catch (error) {
      onError();
      this.handleError(error);
    } finally {
      this.decrementLoadingCount();
    }
  };

  processSearchedInventoryEntries(
    inventoryEntries: APIInventorySearchResults,
    sampleLimitHit: boolean,
    appending = false,
  ) {
    const processed = this.processInventorySearchEntries(inventoryEntries);

    if (appending) {
      this.inventoryEntries = [...this.inventoryEntries, ...processed];
    } else {
      this.inventoryEntries = processed;
    }

    this.sampleLimitHit = sampleLimitHit;
  }

  handleEditInventory(entry) {
    this.currentlyEditingChildIndex = entry.childIndex;
    const inventory_event_fields = entry.row.inventory_events[0]
      ? InventoryStoreHelper.convertNameKeysToFieldIds(
        entry.row.inventory_events[0].inventory_event_fields_hash,
        this.inventory_event_field_definitions,
      )
      : undefined;

    this.currentlyEditingInventory = deepClone({
      ...entry.row,
      inventory_event_fields,
    });
  }

  handleCancelEditInventory() {
    this.currentlyEditingInventory = null;
  }

  refreshDataAndCloseDialog() {
    this.submitQuery().then(() => {
      this.handleCancelEditInventory();
    });
  }

  handleSubmitEditSample() {
    InventorySampleService.handleSubmitEditSample({
      sample: this.currentlyEditingInventoryAsSample,
      moleculeId: this.currentlyEditingInventory.batch.molecule_id,
      on409Error: async () => {
        this.handleError('409 Error');
      },
      onGenericError: async (error) => {
        this.handleError(`Request Error: ${error}`);
      },
      onSubmitSuccess: this.refreshDataAndCloseDialog,
    });
  }

  handleSubmitEditEvent() {
    InventorySampleService.handleSubmitEditEvent({
      on409Error: async () => {
        this.handleError('409 Error');
      },
      onGenericError: async (error) => {
        this.handleError(`Request Error: ${error}`);
      },
      onSubmitSuccess: this.refreshDataAndCloseDialog,
      moleculeId: this.currentlyEditingInventory.batch.molecule_id,
      event: this.currentlyEditingInventoryAsEvent,
    });
  }

  handleDeleteSample() {
    InventorySampleService.handleDeleteSample({
      moleculeId: this.currentlyEditingInventory.batch.molecule_id,
      sample: this.currentlyEditingInventoryAsSample,
      onAfterDelete: this.refreshDataAndCloseDialog,
    });
  }

  handleDeleteEvent() {
    InventorySampleService.handleDeleteEvent({
      event: this.currentlyEditingInventoryAsEvent,
      moleculeId: this.currentlyEditingInventory.batch.molecule_id,
      sample: this.currentlyEditingInventoryAsSample,

      onGenericError: async (error) => {
        this.handleError(`Request Error: ${error}`);
      },
      onAfterDelete: this.refreshDataAndCloseDialog,
    });
  }
}
