import { ColumnVisibilityAndSorting } from '@/Protocols/types';
import { RootStore } from '@/stores/rootStore';
import axios from 'axios';
import _ from 'lodash';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { vaultIdFromUrl } from './routerStore';

import constants from 'ASSETS/javascripts/constants';

const inventoryDefaultVisibleResultColumns =
  constants.INVENTORY_DEFAULT_VISIBLE_RESULT_COLUMNS;

const inventoryDefaultSortColumn = Object.fromEntries(
  constants.INVENTORY_DEFAULT_SORT_COLUMN,
) as ColumnVisibilityAndSorting['sortedColumn'];

const protocolDefaultSortColumn = {
  id: 'name',
  direction: 'asc',
} as const;

export type VaultPreferences = {
  perProtocolRunFieldsColumnPrefs?: {
    [protocolId: number]: ColumnVisibilityAndSorting;
  };
  inventoryColumnPrefs?: ColumnVisibilityAndSorting;
  protocolColumnPrefs?: ColumnVisibilityAndSorting;
};

type ProtocolsPreferencesLegacy = VaultPreferences & ColumnVisibilityAndSorting;

export class VaultPreferencesStore {
  disposers: Array<() => void> = [];

  preferences: VaultPreferences = {};

  inited = false;

  loading: 0;
  saving: 0;

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  incrementLoading() {
    ++this.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.loading;
    this.root.decrementLoading();
  }

  incrementSaving() {
    ++this.saving;
    this.root.incrementSaving();
  }

  decrementSaving() {
    --this.saving;
    this.root.decrementLoading();
  }

  get inventoryColumnPrefs(): ColumnVisibilityAndSorting {
    return {
      sortedColumn: this.preferences.inventoryColumnPrefs?.sortedColumn
        ? this.preferences.inventoryColumnPrefs.sortedColumn
        : inventoryDefaultSortColumn,
      visibleColumnIds: this.preferences.inventoryColumnPrefs?.visibleColumnIds
        ? this.preferences.inventoryColumnPrefs.visibleColumnIds
        : inventoryDefaultVisibleResultColumns,
    };
  }

  get protocolColumnPrefs(): ColumnVisibilityAndSorting {
    return {
      sortedColumn: this.preferences.protocolColumnPrefs?.sortedColumn
        ? this.preferences.protocolColumnPrefs.sortedColumn
        : protocolDefaultSortColumn,
      visibleColumnIds: this.preferences.protocolColumnPrefs?.visibleColumnIds
        ? this.preferences.protocolColumnPrefs.visibleColumnIds
        : [],
    };
  }

  get preferencesUrl() {
    const vaultId = vaultIdFromUrl();
    return vaultId ? `/vaults/${vaultId}/protocols/preferences` : null;
  }

  init() {
    this.disposers.push(
      reaction(
        () => this.preferencesUrl,
        (preferencesUrl) => {
          if (preferencesUrl) {
            this.loadPreferences();
          }
        },
        { fireImmediately: true },
      ),
    );

    this.inited = true;
  }

  cleanup() {
    this.inited = false;
  }

  async loadPreferences() {
    const url = this.preferencesUrl;
    if (!url) {
      return;
    }

    try {
      this.incrementLoading();
      const response = await axios.get<ProtocolsPreferencesLegacy>(url, {
        params: { format: 'json' },
      });
      runInAction(() => {
        // when response is null this endpoint returns an empty array
        const data = Array.isArray(response.data) ? {} : response.data;
        data.protocolColumnPrefs = data.protocolColumnPrefs || {};

        // move the old preferences to the new location (protocolColumnPrefs)
        if (data.sortedColumn) {
          data.protocolColumnPrefs.sortedColumn = data.sortedColumn;
          delete data.sortedColumn;
        }
        if (data.visibleColumnIds) {
          data.protocolColumnPrefs.visibleColumnIds = data.visibleColumnIds;
          delete data.visibleColumnIds;
        }
        this.preferences = data;
      });
    } finally {
      this.decrementLoading();
    }
  }

  updatePreferences(newPrefs: Partial<VaultPreferences>) {
    this.preferences = _.merge({ ...this.preferences }, newPrefs);
    this.savePreferences();
  }

  // for completely setting a property to a new value throwing out old
  setPreference(
    preferenceKey: keyof VaultPreferences,
    value: VaultPreferences[typeof preferenceKey],
  ) {
    this.preferences[preferenceKey] = { ...value };
    this.savePreferences();
  }

  savePreferences() {
    this.incrementSaving();
    axios
      .post(this.preferencesUrl, {
        ...this.preferences,
      })
      .finally(() => {
        this.decrementSaving();
      });
  }
}
