/* eslint-disable dot-notation, no-cond-assign */

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tooltip,
  Typography,
} from '@mui/material';
import SubmitOrCancel from '@/shared/components/CancelOrSubmit.jsx';
import React from 'react';
import './Mixture.sass';
import { MixturesStore } from './mixturesStore';

type Props = {
  store: MixturesStore,
  isOpen: boolean,
  origin: number[],
};

type State = {
  comp: Mixtures.MixfileComponent,

  keyContentState?: string,
  name?: string,
  quantity?: string,
  description?: string;
  synonyms?: string;
  formula?: string;
  vaultMolecule?: string;
  vaultBatch?: string;
  identifiers?: string;
  links?: string;
};

export class DetailComponentDialog extends React.Component<Props, State> {
  private lastOpen = false;

  constructor(props) {
    super(props);
    this.state = {
      comp: null,
    };
  }

  public render() {
    const { store, isOpen, origin } = this.props;
    if (this.lastOpen != isOpen) {
      this.lastOpen = isOpen;
      if (isOpen) {
        setTimeout(() => {
          const comp = store.mixture.getComponent(origin);

          const links = { ...(comp.links ?? {}) };
          const vaultMolecule = links.vaultMolecule as string;
          delete links.vaultMolecule;

          const identifiers = { ...(comp.identifiers ?? {}) };
          const vaultBatch = identifiers.vaultBatch as string;
          delete identifiers.vaultBatch;

          const modState: Partial<State> = {
            name: comp.name,
            quantity: Mixtures.ArrangeMixture.formatQuantity(comp),
            description: comp.description,
            synonyms: (comp.synonyms ?? []).join('\n'),
            formula: comp.formula,
            vaultMolecule,
            vaultBatch,
            identifiers: Object.entries(identifiers).map(([k, v]) => `${k}=${v}`).join('\n'),
            links: Object.entries(links).map(([k, v]) => `${k}=${v}`).join('\n'),
          };
          this.setState({ comp, ...modState, keyContentState: this.deriveContentState(modState) });
        });
      }
    }

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.store.closePasteClipboard()}
        className='EditTeamDialog edit-account-object-dialog'
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        {this.state.comp && this.renderDialog()}
      </Dialog>
    );
  }

  private renderDialog(): JSX.Element {
    const isModified = this.deriveContentState(this.state) != this.state.keyContentState;

    return (
      <>
        <DialogTitle className='muiDialog-title'>
          Edit Component
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          {this.renderContent()}
        </DialogContent>
        <DialogActions>
          <div className="MixtureEditor-twosides">
            <div style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}>
              {/* ... */}
            </div>
            <SubmitOrCancel
              green
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              disabled={!isModified}
            >
              APPLY
            </SubmitOrCancel>
          </div>
        </DialogActions>
      </>
    );
  }

  private renderContent(): JSX.Element {
    if (!this.props.isOpen) return null;

    const { store, origin } = this.props;
    const {
      name,
      quantity,
      description,
      synonyms,
      formula,
      vaultMolecule,
      vaultBatch,
      identifiers,
      links,
    } = this.state;

    const tooltipName = (
      <Typography>
        The primary name or identifying code for the component.
      </Typography>
    );

    const tooltipQuantity = (
      <Typography>
        <span>The amount or concentration of the component, using one of the following syntax patterns:</span>
        <span style={{ display: 'inline-block', whiteSpace: 'pre-line', padding: '0.5em' }}>
          <i>value units</i> (e.g. 10 %)<br/>
          <i>modifier value units</i> (e.g. &gt; 5 mol/L, &lt;= 1.5 M)<br/>
          <i>low-high units</i> (e.g. 1-2 %)<br/>
          <i>value(error) units</i> (e.g. 11.5(.3) mM, 5 +/- 1 %)<br/>
          <i>numerator/denominator</i> (e.g. 1/3 for one third)<br/>
        </span>
        <span>
          Common units: {Mixtures.Units.commonNames().join(', ')}.
        </span>
      </Typography>
    );

    const tooltipDescription = (
      <Typography>
        Free text detailed description.
      </Typography>
    );

    const tooltipSynonyms = (
      <Typography>
        Secondary names for the component: enter each synonym on a separate line.
      </Typography>
    );

    const tooltipFormula = (
      <Typography>
        Molecular formula of the component material. Leave this blank if a well defined chemical structure is available.
        Fractional stoichiometry, uncertainties and non-elements may be included.
      </Typography>
    );

    const tooltipMolecule = (
      <Typography>
        Link to the molecule if it has been registered in Vault.
      </Typography>
    );

    const tooltipBatch = (
      <Typography>
        Vault molecule batch identifier, if applicable.
      </Typography>
    );

    const tooltipIdentifiers = (
      <Typography>
        List of custom identifiers for the component: one identifier per line. Each line should take the format <i>key=id</i> (e.g. pubchemCID=241).
      </Typography>
    );

    const tooltipLinks = (
      <Typography>
        List of custom URL references for the component: one URL per line. Each line should take the format <i>key=url</i> (e.g. vendorLink=http://acme.com/products/123).
      </Typography>
    );

    const quantityStyle: React.CSSProperties = {};
    if (quantity) {
      const comp = WebMolKit.deepClone(store.mixture.getComponent(origin));
      if (!this.applyQuantity(quantity, comp)) {
        quantityStyle.color = 'red';
      }
    }

    return (
      <>
        <div className="MixtureEditor-detail-maingrid">
          <div key="l1" style={{ gridArea: '1 / title' }} className="MixtureEditor-detail-label">
            Name
          </div>
          <div key="c1" style={{ gridArea: '1 / content' }}>
            <Tooltip
              title={tooltipName}
              arrow
              disableFocusListener
              placement="right"
              >
              <input
                id="MixtureDialog-name"
                className="input-text MixtureEditor-detail-input"
                type="text"
                value={name ?? ''}
                onChange={(event) => this.setState({ name: event.target.value })}
                autoFocus={true}
                spellCheck={false}
                />
            </Tooltip>
          </div>

          <div key="l2" style={{ gridArea: '2 / title' }} className="MixtureEditor-detail-label">
            Quantity
          </div>
          <div key="c2" style={{ gridArea: '2 / content' }}>
            <Tooltip
              title={tooltipQuantity}
              arrow
              disableFocusListener
              placement="right"
              >
              <input
                id="MixtureDialog-quantity"
                className="input-text MixtureEditor-detail-input"
                style={quantityStyle}
                type="text"
                value={quantity ?? ''}
                onChange={(event) => this.setState({ quantity: event.target.value })}
                spellCheck={false}
                />
            </Tooltip>
          </div>

          <div key="l3" style={{ gridArea: '3 / title' }} className="MixtureEditor-detail-label">
            Description
          </div>
          <div key="c3" style={{ gridArea: '3 / content' }}>
            <Tooltip
              title={tooltipDescription}
              arrow
              disableFocusListener
              placement="right"
              >
              <textarea
                id="MixtureDialog-description"
                className="resizable MixtureEditor-detail-textarea"
                cols={40}
                rows={4}
                value={description ?? ''}
                onChange={(event) => this.setState({ description: event.target.value })}
                onKeyDown={this.handleConsumeEnterKey}
                spellCheck={false}
                />
            </Tooltip>
          </div>

          <div key="l4" style={{ gridArea: '4 / title' }} className="MixtureEditor-detail-label">
            Synonyms
          </div>
          <div key="c4" style={{ gridArea: '4 / content' }}>
            <Tooltip
              title={tooltipSynonyms}
              arrow
              disableFocusListener
              placement="right"
              >
              <textarea
                id="MixtureDialog-synonyms"
                className="resizable MixtureEditor-detail-textarea"
                cols={40}
                rows={4}
                // placeholder="Enter synonyms on separate lines."
                value={synonyms ?? ''}
                onChange={(event) => this.setState({ synonyms: event.target.value })}
                onKeyDown={this.handleConsumeEnterKey}
                spellCheck={false}
                />
            </Tooltip>
          </div>

          <div key="l5" style={{ gridArea: '5 / title' }} className="MixtureEditor-detail-label">
            Formula
          </div>
          <div key="c5" style={{ gridArea: '5 / content' }}>
            <Tooltip
              title={tooltipFormula}
              arrow
              disableFocusListener
              placement="right"
              >
              <input
                id="MixtureDialog-formula"
                className="input-text MixtureEditor-detail-input"
                type="text"
                value={formula ?? ''}
                onChange={(event) => this.setState({ formula: event.target.value })}
                spellCheck={false}
                />
            </Tooltip>
          </div>

          <div key="l6" style={{ gridArea: '6 / title' }} className="MixtureEditor-detail-label">
            Vault Molecule
          </div>
          <div key="c6" style={{ gridArea: '6 / content' }}>
            <Tooltip
              title={tooltipMolecule}
              arrow
              disableFocusListener
              placement="right"
              >
              <input
                id="MixtureDialog-vaultMolecule"
                className="input-text MixtureEditor-detail-input"
                type="text"
                value={vaultMolecule ?? ''}
                onChange={(event) => this.setState({ vaultMolecule: event.target.value })}
                spellCheck={false}
                />
            </Tooltip>
          </div>

          <div key="l7" style={{ gridArea: '7 / title' }} className="MixtureEditor-detail-label">
            Vault Batch
          </div>
          <div key="c7" style={{ gridArea: '7 / content' }}>
            <Tooltip
              title={tooltipBatch}
              arrow
              disableFocusListener
              placement="right"
              >
              <input
                id="MixtureDialog-vaultBatch"
                className="input-text MixtureEditor-detail-input"
                type="text"
                value={vaultBatch ?? ''}
                onChange={(event) => this.setState({ vaultBatch: event.target.value })}
                spellCheck={false}
                />
            </Tooltip>
          </div>

          <div key="l8" style={{ gridArea: '8 / title' }} className="MixtureEditor-detail-label">
            Identifiers
          </div>
          <div key="c8" style={{ gridArea: '8 / content' }}>
            <Tooltip
              title={tooltipIdentifiers}
              arrow
              disableFocusListener
              placement="right"
              >
              <textarea
                id="MixtureDialog-identifiers"
                className="resizable MixtureEditor-detail-textarea"
                cols={40}
                rows={4}
                value={identifiers ?? ''}
                onChange={(event) => this.setState({ identifiers: event.target.value })}
                onKeyDown={this.handleConsumeEnterKey}
                spellCheck={false}
                />
            </Tooltip>
          </div>

          <div key="l9" style={{ gridArea: '9 / title' }} className="MixtureEditor-detail-label">
            Links
          </div>
          <div key="c9" style={{ gridArea: '9 / content' }}>
            <Tooltip
              title={tooltipLinks}
              arrow
              disableFocusListener
              placement="right"
              >
              <textarea
                id="MixtureDialog-links"
                className="resizable MixtureEditor-detail-textarea"
                cols={40}
                rows={4}
                value={links ?? ''}
                onChange={(event) => this.setState({ links: event.target.value })}
                onKeyDown={this.handleConsumeEnterKey}
                spellCheck={false}
                />
            </Tooltip>
          </div>
        </div>
      </>
    );
  }

  private deriveContentState(state: Partial<State>): string {
    const { name, quantity, description, synonyms, formula, vaultMolecule, vaultBatch, identifiers, links } = state;
    return JSON.stringify({ name, quantity, description, synonyms, formula, vaultMolecule, vaultBatch, identifiers, links });
  }

  private applyToComponent(comp: Mixtures.MixfileComponent): void {
    const { name, quantity, description, synonyms, formula, vaultMolecule, vaultBatch, identifiers, links } = this.state;

    comp.name = name || null;

    if (quantity) {
      this.applyQuantity(quantity, comp);
    }

    comp.description = description || null;

    const synList = synonyms.split('\n').map((syn) => syn.trim()).filter((syn) => syn.length > 0);
    if (synList.length > 0) {
      comp.synonyms = synList;
    } else {
      comp.synonyms = null;
    }

    comp.formula = formula || null;

    comp.links = {};
    if (vaultMolecule) {
      comp.links['vaultMolecule'] = vaultMolecule;
    }
    for (let line of links.split('\n')) {
      line = line.trim();
      const eq = line.indexOf('=');
      if (eq >= 0) {
        comp.links[line.substring(0, eq)] = line.substring(eq + 1);
      }
    }
    if (Object.keys(comp.links).length == 0) {
      comp.links = null;
    }

    comp.identifiers = {};
    if (vaultBatch) {
      comp.identifiers['vaultBatch'] = vaultBatch;
    }
    for (let line of identifiers.split('\n')) {
      line = line.trim();
      const eq = line.indexOf('=');
      if (eq >= 0) {
        comp.identifiers[line.substring(0, eq)] = line.substring(eq + 1);
      }
    }
    if (Object.keys(comp.identifiers).length == 0) {
      comp.identifiers = null;
    }
  }

  private applyQuantity(qstr: string, comp: Mixtures.MixfileComponent): boolean {
    comp.quantity = null;
    comp.error = null;
    comp.ratio = null;
    comp.units = null;
    comp.relation = null;

    qstr = qstr.trim().replace('\u{00B1}', '+/-');

    // scrape out any "relation" properties from the beginning first of all
    let rel = '';
    const RELATION_VALUES:string[] = ['=', '~', '<', '<=', '>', '>='];
    for (const pfx of RELATION_VALUES) if (qstr.startsWith(pfx) && pfx.length > rel.length) rel = pfx;
    if (rel) {
      qstr = qstr.substring(rel.length);
    } else if (qstr.startsWith('\u{2264}')) {
      [rel, qstr] = ['<=', qstr.substring(1)];
    } else if (qstr.startsWith('\u{2265}')) {
      [rel, qstr] = ['>=', qstr.substring(1)];
    }

    qstr = qstr.trim();

    // scrape off units from the end
    let units = '';
    for (const [name, uri] of Object.entries(Mixtures.Units.NAME_TO_URI)) {
      const regname = name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // regexify the unit name
      const regex = new RegExp(`^(.*[\\d\\s)])(${regname})$`);
      const match = regex.exec(qstr);
      if (!match) continue;
      qstr = match[1];
      units = Mixtures.Units.URI_TO_NAME[uri];
      break;
    }

    qstr = qstr.trim();

    const isNum = (str:string):boolean => {
      if (str.startsWith('.')) str = '0' + str;
      if (!/^-?[0-9]+\.?[0-9eE]*$/.test(str)) return false;
      return !isNaN(parseFloat(str));
    };

    const matchOneOf = (regexes:RegExp[]): RegExpExecArray => {
      for (const regex of regexes) {
        const match = regex.exec(qstr);
        if (match) return match;
      }
    };

    let match = matchOneOf([/^([0-9\-.eE]+)\s?-\s?([0-9\-.eE]+)$/, /^([0-9\-.eE]+)\s?\.\.\s?([0-9\-.eE]+)$/]);
    if (match) {
      const [qnum1, qnum2] = [match[1], match[2]]; // A-B or A..B
      if (!isNum(qnum1) || !isNum(qnum2) || !units) return false;
      comp.quantity = [parseFloat(qnum1), parseFloat(qnum2)];
    } else if (match = matchOneOf([/^([0-9\-.eE]+)\(([0-9\-.eE]+)\)$/, /^([0-9\-.eE]+)\s?\+\/-\s?([0-9\-.eE]+)$/])) { // eslint-disable-line security/detect-unsafe-regex
      const [qnum1, qnum2] = [match[1], match[2]]; // A(B) or A +/- B
      if (!isNum(qnum1) || !isNum(qnum2) || !units) return false;
      comp.quantity = parseFloat(qnum1);
      comp.error = parseFloat(qnum2);
    } else if (match = matchOneOf([/^([0-9\-.eE]+):([0-9\-.eE]+)$/, /^([0-9\-.eE]+)\/([0-9\-.eE]+)$/])) {
      const [qnum1, qnum2] = [match[1], match[2]]; // A:B or A/B
      if (!isNum(qnum1) || !isNum(qnum2)) return false;
      comp.ratio = [parseFloat(qnum1), parseFloat(qnum2)];
    } else {
      if (!isNum(qstr) || !units) return false;
      comp.quantity = parseFloat(qstr);
    }

    if (rel) comp.relation = rel;
    if (units) comp.units = units;

    return true;
  }

  private handleConsumeEnterKey = (event: React.KeyboardEvent): void => {
    if (event.key == 'Enter') {
      event.stopPropagation();
    }
  };

  private handleSubmit = (): void => {
    const { store, origin } = this.props;

    const comp = WebMolKit.deepClone(store.mixture.getComponent(origin));
    this.applyToComponent(comp);
    const mixture = store.mixture.clone();
    mixture.setComponent(origin, comp);
    store.setMixture(mixture);
    store.closeComponentDetail();
  };

  private handleCancel = (): void => {
    this.props.store.closeComponentDetail();
  };
}
