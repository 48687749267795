/* eslint-disable no-nested-ternary, multiline-ternary */

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import SubmitOrCancel from '@/shared/components/CancelOrSubmit.jsx';
import React from 'react';
import './Mixture.sass';
import { MixtureClipboardType, MixturesStore } from './mixturesStore';

type Props = {
  store: MixturesStore,
  isOpen: boolean,
  initText: string,
};

type State = {
  currentText: string,
};

export class PasteMixtureDialog extends React.Component<Props, State> {
  private lastOpen = false;

  constructor(props) {
    super(props);
    this.state = {
      currentText: props.initText,
    };
  }

  public render() {
    const { isOpen } = this.props;
    if (this.lastOpen != isOpen) {
      this.lastOpen = isOpen;
      if (isOpen) {
        setTimeout(() => this.setState({ currentText: this.props.initText }));
      }
    }

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={() => this.props.store.closePasteClipboard()}
        className='EditTeamDialog edit-account-object-dialog'
        PaperProps={{ className: 'main-dialog-paper' }}
      >
        {this.renderDialog()}
      </Dialog>
    );
  }

  private renderDialog(): JSX.Element {
    const { store } = this.props;
    const what = store.investigateCurrentText(this.state.currentText);
    const txtInfo = what == MixtureClipboardType.MixtureFormat ? 'pasting mixture component'
      : what == MixtureClipboardType.MoleculeFormat ? 'pasting molecular structure'
        : what == MixtureClipboardType.UnknownFormat ? 'unknown text format' : '';

    return (
      <>
        <DialogTitle className='muiDialog-title'>
          Paste Mixture
        </DialogTitle>
        <DialogContent className="OntologyTemplate-toppadding">
          {this.renderContent()}
        </DialogContent>
        <DialogActions>
          <div className="MixtureEditor-twosides">
            <div style={{ marginLeft: '10px', whiteSpace: 'nowrap' }}>
              {txtInfo}
            </div>
            <SubmitOrCancel
              green
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              disabled={what == MixtureClipboardType.Blank || what == MixtureClipboardType.UnknownFormat}
            >
              PASTE
            </SubmitOrCancel>
          </div>
        </DialogActions>
      </>
    );
  }

  private renderContent(): JSX.Element {
    if (!this.props.isOpen) return null;

    return (
      <>
        <textarea
          className="OntologyComponent-textarea resizable OntologyTemplate-clipboardtext"
          cols={40}
          rows={6}
          value={this.state.currentText}
          onChange={this.handleChangeText}
          autoFocus={true}
          spellCheck={false}
        />
      </>
    );
  }

  private handleChangeText = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({ currentText: event.target.value });
  };

  private handleSubmit = (): void => {
    const { store } = this.props;
    const { currentText } = this.state;

    const origin = store.selectedOrigin;
    if (!origin || !store.mixture.getComponent(origin)) return;

    if (store.actionPasteText(origin, currentText)) {
      store.closePasteClipboard();
    }
  };

  private handleCancel = (): void => {
    this.props.store.closePasteClipboard();
  };
}
